@font-face {
    font-family: 'imported-font-varela';
    src: url('./VarelaRound-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'imported-font-sacramento';
    src: url('./Sacramento-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'imported-font-poppins';
    src: url('./Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'playfair';
    src: url('./PlayfairDisplay-Regular.ttf') format('truetype');
}




