.modalApp {
    font-family: sans-serif;
    text-align: center;
  }
  
  .modalClass {
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -45%);
  
    border: 3px solid #034488;
    /* #fffcf6 #034488 edece8 */
    /* border: 1px solid #ccc; */
    /* background: #fff; */
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 0px;
    z-index: 50;
  }
  
  .overlayClass {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(0, 0, 0, 0.75); */
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 51;
    height: '120vh',

  }
  
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  

  